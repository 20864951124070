import { useEffect } from 'react';

const Vflwolfsburg = () => {
  useEffect(() => {
    localStorage.setItem('club_id', 565);
    localStorage.setItem('club_name', 'VFL Wolfsburg');

    window.location.assign('/');
  });

  return null;
};
export default Vflwolfsburg;
